.components-mobile__header__settings {
  display: none;
}

.match-settings__wrapper {
  /*margin-top: 0px;*/
  /*margin-bottom: 0px;*/
}

.match-settings__header {
  padding: 24px 24px 0 24px;
}

.match-settings__body {
  padding: 0 24px 24px 24px;
}

.match-settings__language {
  margin-bottom: 0px;
}

.match-settings__divider {
  margin: 12px;
  border-top: 1px solid rgba(160, 160, 160, 0.2);
}

.match-settings__text-heading {
  color: #F44C40;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.match-settings__text-primary {
  text-transform: uppercase;
}

.match-settings__text-muted {
  color: #bfbfbf;
  text-transform: uppercase;
}

.match-settings__order-by__button {
  width: 50%;
  text-transform: uppercase;
  float: left;
  text-align: center;
  background-color: #f5f5f5;
  padding: 8px 2em;
  border: 0.1em solid #e0e0e0;
  color: #b0bec5;
  cursor: pointer;
}

.match-settings__order-by__button--active {
  background-color: #f44336;
  color: #ffffff;
}

.match-settings__order-by__buttons {
  margin-bottom: 0px;
}

.match-settings__wrapper {
  /*margin-top: 0px;*/
  /*margin-bottom: 0px;*/
}

.match-settings__header {
  padding: 24px 24px 0 24px;
}

.match-settings__body {
  padding: 0 24px 24px 24px;
}

.match-settings__language {
  margin-bottom: 0px;
}

.match-settings__divider {
  margin: 12px;
  border-top: 1px solid rgba(160, 160, 160, 0.2);
}

.match-settings__text-heading {
  color: #F44C40;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.match-settings__text-primary {
  text-transform: uppercase;
}

.match-settings__text-muted {
  color: #bfbfbf;
  text-transform: uppercase;
}

.match-settings__order-by__button {
  width: 50%;
  text-transform: uppercase;
  float: left;
  text-align: center;
  background-color: #f5f5f5;
  padding: 8px 2em;
  border: 0.1em solid #e0e0e0;
  color: #b0bec5;
  cursor: pointer;
}

.match-settings__order-by__button--active {
  background-color: #f44336;
  color: #ffffff;
}

.match-settings__order-by__buttons {
  margin-bottom: 0px;
}